import { get, post, del, patch } from '../helper/API';

export function loadContentLibraryAssessments(query, page, category, config = {}) {
    return get('employer/company-auditions', {
        query,
        page,
        category
    }, config);
}

export function createContentLibraryAssessment(name, categoryUuid = null) {
    return post('employer/company-auditions', {
        name,
        category: categoryUuid
    });
}

export function duplicateContentLibraryAssessment(uuid) {
    return post(`employer/company-auditions/${uuid}/duplicate`);
}


export function loadContentLibraryAssessment(uuid) {
    return get(`employer/company-auditions/${uuid}`);
}

export function removeContentLibraryAssessment(uuid) {
    return del(`employer/company-auditions/${uuid}`);
}

export function editContentLibraryAssessment(uuid, form) {
    return patch(`employer/company-auditions/${uuid}`, form);
}

export function loadContentLibraryAssessmentPreview(uuid) {
    return get(`employer/company-auditions/${uuid}/preview`);
}

export function loadContentLibraryAssessmentCompletedQuestions(uuid) {
    return get(`employer/company-auditions/${uuid}/completed-questions`);
}

export function editCMSQuestionSet(uuid, data) {
    return patch(`employer/company-sets/${uuid}`, data);
}

export function editCategory(uuid, data) {
    return patch(`employer/auditions/categories/${uuid}`, data);
}
export function removeCategory(uuid) {
    return del(`employer/auditions/categories/${uuid}`);
}

export function addCategory(title) {
    return post('employer/auditions/categories', { title });
}

export function getListOfSkills(query = '') {
    return get('employer/company-skills', { query });
}

export function getListOfQuestions(query, companySkill, page, types) {
    return get('employer/company-questions', { query, companySkill, page, types });
}

export function getListOfDuplicatedQuestions(page) {
    return get('employer/company-questions/duplicates', { page });
}

export function createSkill(title, description) {
    return post('employer/company-skills', { title, description });
}

export function updateSkill(id, data) {
    return patch(`employer/company-skills/${id}`, data);
}

export function removeSkill(id) {
    return del(`employer/company-skills/${id}`);
}
export function copyPrivateToCMS(slug) {
    return post(`employer/auditions/${slug}/company-auditions`);
}

export function createCompanyQuestion(data) {
    return post('employer/company-questions', data);
}

export function deleteCompanyQuestion(id, isScreening) {
    return del(`employer/company-${isScreening ? 'screening-' : ''}questions/${id}`);
}

export function reorderCompanyQuestion(uuid, companyQuestionUuid, data) {
    return patch(`employer/company-sets/${uuid}/company-questions/${companyQuestionUuid}/order`, data);
}

export function duplicateCompanyQuestion(id) {
    return post(`employer/company-questions/${id}/duplicate`);
}

export function addSkillToCompanyQuestion(id, companySkillId) {
    return post(`employer/company-questions/${id}/company-skills/${companySkillId}`);
}

export function removeSkillFromCompanyQuestion(id, companySkillId) {
    return del(`employer/company-questions/${id}/company-skills/${companySkillId}`);
}

export function getListOfCompanySets(companySkill, query, page = 1, status, synchronized) {
    return get('employer/company-sets', { companySkill, query, page, status, synchronized });
}

export function getCompanySet(uuid) {
    return get(`employer/company-sets/${uuid}`);
}

export function getCompanyQuestionDuplicates(id) {
    return get(`employer/company-questions/${id}/duplicates`);
}

export function getCompanyQuestionsFilter() {
    return get('employer/company-questions/type-filter');
}

export function createSet(title, description, companySkill) {
    return post('employer/company-sets', { title, description, companySkill });
}

export function updateSet(id, title, description) {
    return patch(`employer/company-sets/${id}`, { title, description });
}

export function deleteSet(id) {
    return del(`employer/company-sets/${id}`);
}

export function reorderCompanySet(uuid, companySetUuid, data) {
    return patch(`employer/company-auditions/${uuid}/company-sets/${companySetUuid}/order`, data);
}

export function addQuestionToSet(uuid, companyQuestionUuid) {
    return post(`employer/company-sets/${uuid}/company-questions/${companyQuestionUuid}`);
}

export function removeQuestionFromSet(uuid, companyQuestionUuid) {
    return del(`employer/company-sets/${uuid}/company-questions/${companyQuestionUuid}`);
}

export function getAuditionStatistics(uuid) {
    return get(`employer/company-auditions/${uuid}/statistics`);
}

export function getAuditionQuestions(uuid, data) {
    return get(`employer/company-auditions/${uuid}/company-questions`, data);
}

export function addSkillToAssessment(uuid, id) {
    return post(`employer/company-auditions/${uuid}/company-skills/${id}/company-sets`);
}

export function addSetToAssessment(uuid, companySetUuid) {
    return post(`employer/company-auditions/${uuid}/company-sets/${companySetUuid}`);
}

export function removeSetFromAssessment(uuid, companySetUuid) {
    return del(`employer/company-auditions/${uuid}/company-sets/${companySetUuid}`);
}

export function getAuditionSetsToUnpublish(uuid) {
    return get(`employer/company-questions/${uuid}/unpublished-auditions-sets`);
}

export function getAuditionSetsToPublish(uuid) {
    return get(`employer/company-questions/${uuid}/published-auditions-sets`);
}

export function publishQuestion(uuid, data) {
    return post(`employer/company-questions/${uuid}/publish`, data);
}

export function unPublishQuestion(uuid, data) {
    return post(`employer/company-questions/${uuid}/unpublish`, data);
}

export function publishSet(uuid, data) {
    return post(`employer/company-sets/${uuid}/publish`, data);
}

export function unPublishSet(uuid, data) {
    return post(`employer/company-sets/${uuid}/unpublish`, data);
}

export function getAuditionsToUnpublishSet(uuid) {
    return get(`employer/company-sets/${uuid}/unpublished-auditions`);
}

export function getAuditionsToPublishSet(uuid) {
    return get(`employer/company-sets/${uuid}/published-auditions`);
}

export function publishAudition(uuid) {
    return post(`employer/company-auditions/${uuid}/publish`);
}

export function unPublishAudition(uuid) {
    return post(`employer/company-auditions/${uuid}/unpublish`);
}

export function deleteCompanyCategory(categoryId) {
    return del(`employer/company-questions/categories/${categoryId}`);
}

export function getListOfPopularSkills(data) {
    return get('employer/company-skills', data);
}

export function getAssessmentTagsList(page) {
    return get('employer/company-audition-tag', { page });
}

export function createAssessmentTag(title) {
    return post('employer/company-audition-tag', { title });
}

export function editAssessmentTag(id, title) {
    return patch(`employer/company-audition-tag/${id}`, { title });
}

export function deleteAssessmentTag(id) {
    return del(`employer/company-audition-tag/${id}`);
}

export function manageAssessmentTags(uuid, data) {
    return post(`employer/company-auditions/${uuid}/tag`, data);
}

export function getQuestionCategories() {
    return get('employer/company-questions-categories');
}

export function addCategoryToQuestion(questionId, category) {
    return patch(`employer/company-questions/${questionId}/category`, { category });
}
