/* eslint-disable no-mixed-spaces-and-tabs */
import { createContext } from 'react';
import { action, computed, observable } from 'mobx';
import { getListOfCompanySets, getCompanySet } from 'requests/CMSRequests';
import { appStore } from 'components/appStore';


class SetLibraryStore {
	@observable setsData = {};

	@observable selectedSkill = null;

	@observable selectedSetUuid = null;

	@observable selectedSetSkill = null;

	@observable setQuestions = [];

	@observable loadingSets = false;

	@observable loadingSetInfo = false;

	@observable page = 1;

	@observable query = '';

	@observable filterStatus = '';

	@observable filterSynchronized = true;

	@observable timerId = null;

	@computed get selectedSetData() {
	    if (!this.setsData || !this.setsData.items) return null;
	    return this.setsData.items.find(set => set.uuid === this.selectedSetUuid);
	}

	@action resetStore = () => {
	    this.setsData = {};
	    this.selectedSkill = null;
	    this.selectedSetUuid = null;
	    this.selectedSetSkill = null;
	    this.loadingSets = false;
	    this.loadingSetInfo = false;
	    this.page = 1;
	    this.query = '';
	    this.filterStatus = '';
	    this.filterSynchronized = true;
	    this.timerId = null;
	};

	@action setSelectedSkill = (skill) => {
	    this.selectedSkill = skill;
	    this.loadSets();
	};

	@action setSelectedSet = (setUuid) => {
	    if (setUuid === null) {
	        this.selectedSetUuid = null;
	        return;
	    }
	    if (this.selectedSetUuid === setUuid) return;
	    this.selectedSetUuid = setUuid;
	    this.loadSetInfo(setUuid);
	};

	@action setPage = (page) => {
	    this.page = page;
	};

	@action setQuery = (query) => {
	    this.query = query;
	};

	@action setFilterStatus = (status) => {
	    this.filterStatus = status;
	};

	@action setFilterSynchronized = (synchronized) => {
	    this.filterSynchronized = synchronized;
	};

	@action updateQuestions = (questions) => {
	    this.setQuestions = questions;
	};

    @action loadSets = (selectLatest = false) => {
        const selectedSkill = this.selectedSkill || null;
        const skill = (selectedSkill && selectedSkill.id) || selectedSkill;

        clearTimeout(this.timerId);
        this.timerId = setTimeout(() => {
            this.loadingSets = true;
            getListOfCompanySets(skill, this.query, this.page, this.filterStatus, this.filterSynchronized)
                .then(({ success, data }) => {
                    if (success && data) {
                        this.setsData = data;
                        if (selectLatest && typeof (selectLatest) !== 'object') {
                            this.loadSetInfo(data.items[0].uuid);
                        }
                    }
                })
                .catch(({ response }) => {
                    if (response && response.data && response.data.msg) {
                        const { data: { msg } } = response;
                        appStore.flashMessage(msg, 'error');
                    }
                })
                .finally(() => {
                    this.loadingSets = false;
                });
        }, 300);
    };

	@action loadSetInfo = (setUuid) => {
	    if (!setUuid) return;

	    this.loadingSetInfo = true;
	    getCompanySet(setUuid)
	        .then(({ success, data }) => {
	            if (success && data) {
	                this.selectedSetUuid = setUuid;
	                this.setQuestions = data.companyQuestions;
	                this.selectedSetSkill = data.companySkill;
	            }
	        })
	        .catch(({ response }) => {
	            if (response && response.data && response.data.msg) {
	                const { data: { msg } } = response;
	                appStore.flashMessage(msg, 'error');
	            }
	        })
	        .finally(() => {
	            this.loadingSetInfo = false;
	        });
	}
}

export const setLibrary = new SetLibraryStore();
export const setLibraryCtx = createContext(setLibrary);
