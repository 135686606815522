import React, { useEffect, useState, useContext } from 'react';
import * as qs from 'query-string';
import InnerContentWrapper from 'pages/assessment/components/InnerContentWrapper';
import PageWrapper from 'components/layout/PageWrapper';
import { loadCompany } from 'requests/APIrequests';
import Typography from '@mui/material/Typography';
import { atsLogin, getToken } from 'requests/LoginRequests';
import { appCtx } from 'components/appStore';
import { getCompanyTags } from 'requests/SettingsRequests';
import MiscellaneousEvents from 'events/MiscellaneousEvents';

import {
    getAuditionQuestionResults, getAuditionScreeningQuestionResults,
    getCandidatePosts, getEmployerGradeQuestion,
    gradeEmployerAnswer, addCandidateComment
} from 'requests/JobOpportunityRequests';
import { getUserAuditionByUserAuditionUuid, getAuditionByUserAuditionUuid } from 'requests/IntegrationCardRequests';
import CardCompanyLogo from 'components/candidate_cards/CardCompanyLogo';
import CardFooter from 'components/candidate_cards/PublicCardContent/CardFooter';
import CandidateCardWrapper from 'components/candidate_cards/CandidateCardWrapper';
import { observer } from 'mobx-react-lite';


const EmployerIntegrationCard = observer(({ match, history, location }) => {
    const [user, setUser] = useState({});
    const [audition, setAudition] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    const [companyTags, setCompanyTags] = useState([]);
    const { userAuditionUuid } = match.params;
    const { flashMessage, company, setCompany, loggedUser, loadUser } = useContext(appCtx);

    useEffect(() => {
        const parsed = qs.parse(location.search);
        const { token } = parsed;
        if (token && userAuditionUuid) {
            atsLogin(token, userAuditionUuid)
                .then((response) => {
                    const { success, data: { refreshToken } } = response;
                    if (success) {
                        getToken(refreshToken)
                            .then(({ accessToken, refreshToken: refreshTokenNew }) => {
                                if (accessToken) {
                                    sessionStorage.removeItem('token');
                                    sessionStorage.removeItem('refreshToken');
                                    sessionStorage.setItem('refreshToken', refreshTokenNew);
                                    sessionStorage.setItem('token', accessToken);
                                    history.push(location.pathname);
                                    getData();
                                    loadUser();
                                }
                            });
                    }
                })
                .catch((error) => {
                    flashMessage('Unable to authorize', 'error');
                    setIsLoading(false);
                    console.log(error);
                });
        } else {
            getData();
            loadUser();
        }
    }, []);

    const goToLogin = () => {
        history.push('/login');
    };

    const getData = () => {
        Promise.all([getTags(), getCompany(), getAudition(), getUserAudition()])
            .then((values) => {
                const newCompany = values[1];
                const newAudition = values[2];
                const newUser = values[3];
                if (firstLoad && newUser && newCompany && newAudition) {
                    setFirstLoad(false);
                    MiscellaneousEvents.INTEGRATION_CARD_VIEWED({
                        candidateUUID: newUser.uuid,
                        ttUUID: newAudition.uuid,
                        ttName: newAudition.name,
                        companySlug: newCompany.slug,
                        companyName: newCompany.name
                    });
                }
            });
    };

    const getTags = () => getCompanyTags()
        .then(({ success, data }) => {
            if (success && data) {
                setCompanyTags(data);
            }
        });


    const getCompany = () => loadCompany()
        .then((data) => {
            if (data.success) {
                setCompany(data.data);
                return data.data;
            }
        });

    const getUserAudition = () => getUserAuditionByUserAuditionUuid(userAuditionUuid)
        .then(({ data, success }) => {
            if (data && success) {
                const newUser = { ...data };
                if (data.candidateUuid) newUser.uuid = data.candidateUuid;
                setUser(newUser);
                return newUser;
            }
        })
        .catch(() => {
            goToLogin();
        })
        .finally(() => {
            setIsLoading(false);
        });

    const getAudition = () => getAuditionByUserAuditionUuid(userAuditionUuid)
        .then(({ data, success }) => {
            if (data && success) {
                setAudition(data);
                return data;
            }
        });


    return (
        <PageWrapper className="u-pdn--bx5">
            <CardCompanyLogo
                company={company}
                auditionBrand={user?.brand}
                gradeableCard
            />
            <InnerContentWrapper
                className="u-pdn--x0"
                contentClassName="u-pdn--x0"
            >
                <CandidateCardWrapper
                    user={user}
                    isIntegration
                    roleType={loggedUser?.roleType}
                    context="integration card"
                    setUser={setUser}
                    company={company}
                    isLoading={isLoading}
                    getUserInfo={getUserAudition}
                    opportunitySlug={audition.opportunitySlug}
                    audition={audition}
                    loadCandidatePosts={getCandidatePosts}
                    addCandidateComment={addCandidateComment}
                    loadGradeQuestion={getEmployerGradeQuestion}
                    getAuditionQuestionResults={getAuditionQuestionResults}
                    getAuditionScreeningQuestionResults={getAuditionScreeningQuestionResults}
                    gradeFunction={gradeEmployerAnswer}
                    companyTags={companyTags}
                    ReportHeaderComp={(
                        <Typography variant="h4" className="u-txt--left" style={{ whiteSpace: 'pre-wrap' }}>
                            {audition.name}
                        </Typography>
                    )}
                />
            </InnerContentWrapper>
            <CardFooter company={company} />
        </PageWrapper>
    );
});

export default EmployerIntegrationCard;
