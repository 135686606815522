import React from 'react';
import { withTheme, withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import TableFilter from 'components/table_components/table_filter';
import Switch from 'libraries/SwitchBrandWithLabel';

const styles = theme => ({
    additionalFormWrapper: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        padding: 12,
        '& label': {
            marginBottom: 0
        }
    },
    innerContent: {
        backgroundColor: theme.palette.blue[50],
        padding: '8px 12px 0',
        borderRadius: 4
    }
});

const ContentQuestionsTableFilter = observer(({ filter, options, handleChangeFilters, filterSynchronized, onSyncChange, classes }) => (
    <div>
        <TableFilter
            isFilterActive={filter.length > 0}
            innerHeader="Set State"
            options={options}
            handleChange={handleChangeFilters}
            isCheckbox={false}
        >
            <div className={classes.additionalFormWrapper}>
                <div className={classes.innerContent}>
                    <Switch
                        label={filterSynchronized ? 'Synchronized' : 'Unsynchronized'}
                        checked={filterSynchronized}
                        value={filterSynchronized}
                        onChange={() => onSyncChange(!filterSynchronized)}
                    />
                </div>
            </div>
        </TableFilter>
    </div>
));

export default withStyles(styles)(withTheme(ContentQuestionsTableFilter));
