import React, { useState, useContext, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import Layout from 'components/layout';
import InnerHeader from 'components/layout/InnerHeader';
import DrawerPageWrapper from 'components/layout/DrawerPageWrapper';

import ContentLibraryTabs from '../common/ContentLibraryTabs';
import ContentSets from './content_sets';
import CreateSetDialog from '../common/CreateSetDialog';
import QuestionDrawer from '../common/QuestionDrawer';
import AddQuestionDrawer from '../common/AddQuestionDrawer';

import styles from './styles';
import { setLibraryCtx } from './store';

const ContentLibrary = observer(({ classes }) => {
    const [openCreateDrawer, setOpenCreateDrawer] = useState(false);
    const [openAddQuestionDrawer, setOpenAddQuestionDrawer] = useState(false);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [selectedSkill, setSelectedSkill] = useState(null);
    const [selectedQuestionId, setSelectedQuestionId] = useState(null);

    const { resetStore, setPage, loadSets, loadSetInfo, selectedSetUuid, selectedSetData, selectedSetSkill } = useContext(setLibraryCtx);

    useEffect(() => () => {
        resetStore();
    }, []);

    useEffect(() => {
        setOpenAddQuestionDrawer(false);
    }, [selectedSkill, selectedSetUuid]);

    const handleOpenCreateDrawer = () => {
        setOpenCreateDrawer(true);
    };

    const handleSelectedSkillId = (id) => {
        handleOpenCreateDrawer();
        setSelectedQuestionId(id);
    };

    const toggleCreateDrawer = () => {
        setOpenCreateDrawer(!openCreateDrawer);
    };

    const toggleAddQuestionDrawer = () => {
        setOpenAddQuestionDrawer(!openAddQuestionDrawer);
    };

    const handleCreateDialog = () => {
        setOpenCreateDialog(!openCreateDialog);
    };

    const onCreateSet = () => {
        setPage(1);
        loadSets(true);
    };

    const onCloseQuestion = () => {
        loadSetInfo(selectedSetUuid);
    };

    const onCloseAddQuestion = () => {
        loadSetInfo(selectedSetUuid);
    };

    return (
        <Layout>
            <InnerHeader
                header="Set Library"
                className={classes.wrapper}
                rightPart={(
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setOpenCreateDialog(true)}
                    >
                        Create New Set
                    </Button>
                )}
            />
            <DrawerPageWrapper contentWrapperCls="u-fdir--column" className="u-pdn--bx5">
                <ContentLibraryTabs />
                <ContentSets
                    setSelectedQuestionId={handleSelectedSkillId}
                    setSelectedSkill={setSelectedSkill}
                    selectedSkill={selectedSkill}
                    handleOpenCreateDrawer={handleOpenCreateDrawer}
                    handleOpenAddQuestionDrawer={toggleAddQuestionDrawer}
                    selectedQuestionId={selectedQuestionId}
                />
                <QuestionDrawer
                    open={openCreateDrawer}
                    selectedSet={selectedSetData}
                    selectedSkill={selectedSetSkill}
                    selectedQuestionId={selectedQuestionId}
                    setSelectedQuestionId={setSelectedQuestionId}
                    toggleDrawer={toggleCreateDrawer}
                    addToSelectedSkill
                    onClose={onCloseQuestion}
                />
                <AddQuestionDrawer
                    open={openAddQuestionDrawer}
                    selectedSet={selectedSetData}
                    selectedSkill={selectedSkill}
                    toggleDrawer={toggleAddQuestionDrawer}
                    onClose={onCloseAddQuestion}
                />
            </DrawerPageWrapper>
            <CreateSetDialog
                openedDialog={openCreateDialog}
                handleDialog={handleCreateDialog}
                selectedSkill={selectedSkill}
                onSuccess={onCreateSet}
            />
        </Layout>
    );
});

export default withRouter(withStyles(styles)(ContentLibrary));
