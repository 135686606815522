import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import ReactDraftWysiwyg from 'components/inputs/ReactDraftWysiwyg';
import ContentAccordition from '../ContentAccordition';
import IconsBar from '../IconsBar';

const styles = () => ({
    wrapper: {
        position: 'relative',
        marginBottom: 30
    },
    tooltip: {
        textAlign: 'center',
        width: 210
    },
    warningText: {
        fontWeight: 500,
        fontSize: 12,
        margin: '7px 0'
    }
});

const ScoringInstructions = ({
    classes, isCMS, changeQuestion, question: {
        scoringInstruction, scoringInstructionRichTextEditor
    }, disabled
}) => {
    const [scoringValueForCount, setScoringValueForCount] = useState('');

    const onChange = (richTextEditorValue, plainText, html) => {
        setScoringValueForCount(plainText);
        changeQuestion({
            scoringInstruction: html,
            scoringInstructionRichTextEditor: richTextEditorValue,
            scoringInstructionPlainText: plainText
        });
    };

    const header = <Typography variant="h6">Scoring instructions</Typography>;
    const content = (
        <>
            <div className={classes.warningText}>
                Scoring Instructions give your team guidance on what you value in a candidates answer. These will be visible in question grading
            </div>
            <ReactDraftWysiwyg
                value={scoringInstructionRichTextEditor}
                html={scoringInstruction}
                onEditorStateChange={onChange}
                delay={300}
                placeholder="e.g “If answer doesn’t mention inbound sales, should get below a 4.”"
                disabled={disabled}
                limit={4999}
            />
            <IconsBar
                allowedLength={5000}
                questionValue={scoringValueForCount}
            />
        </>
    );

    if (isCMS) {
        return (
            <ContentAccordition
                header={header}
                content={content}
                className={classes.accordion}
            />
        );
    }

    return (
        <div className={classes.wrapper}>
            {header}
            <br />
            {content}
        </div>
    );
};

export default withStyles(styles)(ScoringInstructions);
